.card {
  padding: 5px;
  border: 1px solid var(--light-blue);
  max-width: 500px;
}
.courseCard {
  padding: 5px;
  border: 1px solid #999;
  max-width: 300px;
}
