/* INTRO SECTION */
/* INTRO SECTION */
/* INTRO SECTION */
.intro-section {
  position: relative;
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  background-color: var(--color-dark);
  clip-path: polygon(0 0, 100% 0, 100% 70vh, 0% 100%);
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
}

.intro-content {
  padding: 10rem 2.5rem;
  z-index: 1;
}

.intro-text {
  width: 65%;
}

.intro-text h1 {
  display: block;
  font-size: 5rem;
  font-weight: 700;
  color: var(--color-white);
}

.first-span {
  font-size: 3rem;
  display: block;
  text-transform: capitalize;
  color: var(--color-grey);
  opacity: 0.7;
}

.second-span::before {
  content: "ANIMATION";
  animation: text-changer 8s infinite 2s;
}

@keyframes text-changer {
  0% {
    content: "FrontEnd Devp";
    color: var(--color-primary);
  }
  25% {
    content: "Responsive Web Design";
    color: rgba(255, 255, 255, 0.7);
  }
  50% {
    content: "Software Development";
    color: var(--color-danger);
  }
  75% {
    content: "Build Web Apps";
    color: rgba(255, 255, 255, 0.7);
  }
  100% {
    content: "To Code";
    color: var(--color-primary);
  }
}

/* TABLET */
@media screen and (max-width: 769px) {
  /* INTRO SECTION */
  .intro-content {
    padding: 4rem 2.5rem;
  }

  .intro-text h1 {
    font-size: 4rem;
  }
}

/* MOBILE QUERIES */
@media screen and (max-width: 600px) {
  /* INTRO SECTION */
  .intro-content {
    padding: 7rem 2.5rem;
  }

  .intro-content h1 {
    font-size: 3.5rem;
  }

  .intro-text {
    width: 100%;
    text-align: center;
  }

  .first-span {
    font-size: 2rem;
  }

  .intro-text p {
    text-align: center;
    text-justify: inter-word;
  }
}
