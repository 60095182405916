/* SERVICE SECTION */
.service-section {
  background-color: var(--light-grey);
}

.service {
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  row-gap: 3rem;
  column-gap: 3rem;
}

.service-box {
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 3px solid var(--purple);
  border-radius: 5px;
  padding: 1.5rem;
  background-color: var(--white);
  cursor: pointer;
  box-shadow: var(--box-shadow);
  transform: translateY();
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}

.service-box:hover {
  border: 1px solid var(--color-text);
  background-color: var(--grey);
  transform: translateY(-2rem);
  box-shadow: var(--box-shadow);
}

.service-box::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: var(--color-primary);
  transform: translateX(-100%);
  transform-origin: left;
  transition: all 0.5s;
}

.service-box:hover::before {
  transform: translateX(0);
}

.service-box i {
  font-size: 3rem;
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.service-box:nth-child(even) i {
  color: var(--color-secondary);
}

.t-head {
  font-size: 2rem;
  font-weight: 400;
  color: var(--dark);
  text-transform: capitalize;
  margin-bottom: 2rem;
}

.service-box p {
  font-size: 1.4rem;
  /* text-align: justify; */
}
